import miljo1 from "../img/stock/miljo1.jpg";
import miljo2 from "../img/stock/miljo2.jpg";
import miljo3 from "../img/stock/miljo3.jpeg";
import miljo4 from "../img/stock/miljo4.jpg";

import AzureLogo from "../img/Technology-Icons/Azure-logo.png";
import CssLogo from "../img/Technology-Icons/CSS-logo.png";
import GatsbyLogo from "../img/Technology-Icons/Gatsby-logo.png";
import HtmlLogo from "../img/Technology-Icons/HTML-logo.png";
import JsLogo from "../img/Technology-Icons/JS-logo.png";
import ReactLogo from "../img/Technology-Icons/react-logo.png";
import SqlLogo from "../img/Technology-Icons/sql-logo.png";
import CSharplogo from "../img/Technology-Icons/CSharp-logo.png";
import komplettTechLogo from "../img/Technology-Icons/technology-komplett-logo.png";
import placeholderIcon from "./placeholderIcon.png";

import LinkedinLogo from "../img/social/linkedinShare.png";

export { miljo1, miljo2, miljo3, miljo4 };
export {
  AzureLogo,
  CssLogo,
  GatsbyLogo,
  HtmlLogo,
  JsLogo,
  ReactLogo,
  SqlLogo,
  CSharplogo,
  komplettTechLogo,
};
export { LinkedinLogo };
export { placeholderIcon };
